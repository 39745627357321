<template>
    <v-layout row class="pt-1 pb-1">
        <v-flex pl-5 py-0 :class="standAlone ? 'xs6 lg5 md5' : 'xs6 lg3 md4'">
            <div class="d-flex flex-row align-center">
                <div>
                    <v-icon class="pr-2">{{ fileIcon }}</v-icon>
                </div>
                <div class="text-no-wrap">{{ file.Document.doctype_id >= 999 ? (document != null && document.AssetDocument.asset_title != null ? document.AssetDocument.asset_title : file.Document.title) : file.Document.title }}</div>
                <div style="width: 100%" class="text-right">
                    <v-icon :color="file.Document.customeraccess == 1 ? 'green darken-1' : 'red darken-1'">{{ file.Document.customeraccess == 1 ? 'mdi-lock-open-outline' : 'mdi-lock-outline' }}</v-icon>
                </div>
            </div>
        </v-flex>
        <v-flex py-0 :class="standAlone ? 'xs6 lg4 md5' : 'xs6 lg3 md4'">
            <v-file-input
                disabled
                class="py-0"
                dense
                hide-details="auto"
                solo
                truncate-length="50"
                v-model="uploadObject"
            />
        </v-flex>
        <div class="d-flex flex-row align-center">
            <v-btn
                :loading="generating"
                :disabled="generating || file.Document.linked == 1"
                color="default"
                small
                @click="generateFile(file.Document.fieldname)"
                v-if="![413, 414].includes(file.Document.doctype_id)"
            >{{ document ? $t('message.regenerate') : $t('message.generate') }}</v-btn>
            <v-btn
                :disabled="generating || file.Document.linked == 1"
                color="default"
                small
                @click="openGenerateDueDiligenceAssessmentDialog"
                v-if="file.Document.doctype_id == 413"
            >{{ document ? $t('message.regenerate') : $t('message.generate') }}</v-btn>
            <v-btn
                :disabled="generating || file.Document.linked == 1"
                color="default"
                small
                @click="openGenerateSelfDeclarationLegalHarvestDialog"
                v-if="file.Document.doctype_id == 414"
            >{{ document ? $t('message.regenerate') : $t('message.generate') }}</v-btn>
        </div>
        <div class="d-flex flex-row align-center" v-if="fileObject.AssetDocument.location != null">
            <div class="ml-5 d-flex flex-row align-center" v-if="fileObject.AssetDocument.uploaded">{{ $t('message.lastUpload') + ': ' + timestampToDate(parseInt(fileObject.AssetDocument.uploaded)) }}</div>
            <v-btn icon @click="viewFile(fileObject.AssetDocument.location)" small>
                <v-icon class="pl-2" small>fas fa-cloud-download-alt</v-icon>
            </v-btn>
            <v-btn icon @click="deleteFile(fileObject)" small>
                <v-icon class="pl-2" small>fas fa-trash</v-icon>
            </v-btn>
        </div>
        <PrintDueDiligenceAssessmentLetter
            :key="keyDueDiligenceAssessmentLetter"
            :contract-id="assetId"
            @pdf-uploading="autogeneratedDocumentUploading"
            @pdf-uploaded="autogeneratedDocumentUploaded"
            @pdf-not-uploaded="autogeneratedDocumentNotUploaded"
            v-if="hasDueDiligenceAssessmentLetter"
        />
        <PrintDueDiligenceAssessment
            :dialog.sync="dueDiligenceAssessmentDialog"
            :key="keyDueDiligenceAssessment"
            :contract-id="assetId"
            @dialog-closed="dueDiligenceAssessmentDialogClosed"
            @pdf-uploading="autogeneratedDocumentUploading"
            @pdf-uploaded="autogeneratedDocumentUploaded"
            @pdf-not-uploaded="autogeneratedDocumentNotUploaded"
        />
        <PrintSelfDeclarationLegalHarvesting
            :dialog.sync="selfDeclarationLegalHarvestingDialog"
            :key="keySelfDeclarationLegalHarvesting"
            :contract-id="assetId"
            @dialog-closed="selfDeclarationLegalHarvestingDialogClosed"
            @pdf-uploading="autogeneratedDocumentUploading"
            @pdf-uploaded="autogeneratedDocumentUploaded"
            @pdf-not-uploaded="autogeneratedDocumentNotUploaded"
        />
    </v-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {formatDate, log} from "Helpers/helpers";
import {api} from "Api";
import AlertMissingValues from "Components/Appic/AlertMissingValues";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";

const PrintDueDiligenceAssessment = () => import('Components/Appic/EutrEudr/PrintDueDiligenceAssessment');
const PrintDueDiligenceAssessmentLetter = () => import('Components/Appic/EutrEudr/PrintDueDiligenceAssessmentLetter');
const PrintSelfDeclarationLegalHarvesting = () => import('Components/Appic/EutrEudr/PrintSelfDeclarationLegalHarvesting');

export default {
    name: "FileAutoGenerate",
    props: ['document','file','fileNamePrefix','module','updateMode','standAlone','assetId'],
    components: {
        PrintDueDiligenceAssessment,
        PrintDueDiligenceAssessmentLetter,
        PrintSelfDeclarationLegalHarvesting
    },
    data(){
        return {
            fileObject: {
                AssetDocument: {
                    id: this.document?.AssetDocument?.id,
                    asset: this.document?.AssetDocument?.asset,
                    asset_id: this.document?.AssetDocument?.asset_id,
                    customeraccess: this.document?.AssetDocument?.customeraccess,
                    doctype_id: this.document?.AssetDocument?.doctype_id,
                    asset_title: this.document?.AssetDocument?.asset_title,
                    location: this.document?.AssetDocument?.location,
                    asset_type: this.document?.AssetDocument?.asset_type,
                    uploaded: this.document?.AssetDocument?.uploaded
                }
            },
            hasDueDiligenceAssessment: false,
            hasDueDiligenceAssessmentLetter: false,
            hasSelfDeclarationLegalHarvesting: false,
            keyDueDiligenceAssessment: 'dda_1',
            keyDueDiligenceAssessmentLetter: 'ddal_1',
            keySelfDeclarationLegalHarvesting: 'sdlh_1',
            generating: false,
            uploadObject: {name: '', type: ''},
            dueDiligenceAssessmentDialog: false,
            selfDeclarationLegalHarvestingDialog: false
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        fileIcon() {
            let icon = null
            switch(this.fileType){
                case 'pdf':
                    icon = 'far fa-file-pdf'
                    break;
                case 'bmp':
                    icon = 'far fa-file-image'
                    break;
                case 'jpg':
                    icon = 'far fa-file-image'
                    break;
                case 'png':
                    icon = 'far fa-file-image'
                    break;
                case 'xls':
                    icon = 'far fa-file-excel'
                    break;
                case 'xlxs':
                    icon = 'far fa-file-excel'
                    break;
                default:
                    icon = 'far fa-file-pdf'
            }
            return icon
        },
        regenerate() {
            return true
        }
    },
    methods: {
        ...mapActions('document', {
            deleteDocument: 'deleteDocument',
        }),
        autogeneratedDocumentUploading() {
            // this.generating = true
        },
        autogeneratedDocumentNotUploaded() {
            this.generating = false
            this.hasDueDiligenceAssessment = false
            this.hasDueDiligenceAssessmentLetter = false
            this.hasSelfDeclarationLegalHarvesting = false

        },
        autogeneratedDocumentUploaded(document) {
            this.generating = false
            this.hasDueDiligenceAssessment = false
            this.hasDueDiligenceAssessmentLetter = false
            this.hasSelfDeclarationLegalHarvesting = false

            this.fileObject.AssetDocument.id = document?.AssetDocument.id
            this.fileObject.AssetDocument.asset = document?.AssetDocument.asset
            this.fileObject.AssetDocument.asset_id = document?.AssetDocument.asset_id
            this.fileObject.AssetDocument.doctype_id = document?.AssetDocument.doctype_id
            this.fileObject.AssetDocument.asset_title = document?.AssetDocument.asset_title
            this.fileObject.AssetDocument.location = document?.AssetDocument.location
            this.fileObject.AssetDocument.asset_type = document?.AssetDocument.asset_type
            this.fileObject.AssetDocument.uploaded = document?.AssetDocument.uploaded
            this.fileObject.AssetDocument.customeraccess = document?.AssetDocument.customeraccess
            const fileName = document?.AssetDocument.location.split('/').slice(-1)
            this.uploadObject = new File([],fileName)
            this.$emit('generated-file-done')
        },
        async deleteFile(obj){
            if (await this.$root.$confirm(this.$t('message.deleteDocument'), this.$t('message.confirmations.continueDocumentDelete'), {color: 'orange'})) {
                if (obj.AssetDocument.id != null) {
                    this.deleteDocument(obj.AssetDocument.id)
                }
                this.fileObject.AssetDocument.id = null
                this.fileObject.AssetDocument.asset = null
                this.fileObject.AssetDocument.asset_id = null
                this.fileObject.AssetDocument.doctype_id = null
                this.fileObject.AssetDocument.asset_title = null
                this.fileObject.AssetDocument.location = null
                this.fileObject.AssetDocument.asset_type = null
                this.fileObject.AssetDocument.uploaded = null
                this.fileObject.AssetDocument.customeraccess = null
                this.$emit('delete-file-done', obj.AssetDocument.id)
                // if(this.uploadObject) this.uploadObject.name = ''
                this.uploadObject = new File([],'')
            }
        },
        dueDiligenceAssessmentDialogClosed() {
            this.dueDiligenceAssessmentDialog = false
        },
        generateFile(document) {
            this.generating = true
            switch(document){
                case 'eutrDueDiligenceAssess':
                    this.hasDueDiligenceAssessment = true
                    break;
                case 'eutrDueDiligenceAssessLetter':
                    this.hasDueDiligenceAssessmentLetter = true
                    break;
                case 'selfDeclarationHarvest':
                    this.hasSelfDeclarationLegalHarvesting = true
                    break
            }
            return true
        },
        openGenerateDueDiligenceAssessmentDialog() {
            this.dueDiligenceAssessmentDialog = true
        },
        openGenerateSelfDeclarationLegalHarvestDialog() {
            this.selfDeclarationLegalHarvestingDialog = true
        },
        selfDeclarationLegalHarvestingDialogClosed() {
            this.selfDeclarationLegalHarvestingDialog = false
        },
        timestampToDate(timestamp) {
            return new Date(timestamp).toLocaleString();
        },
        viewFile(file) {
            let tab = window.open(file, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    created() {
        // log(this.document)
        if(this.document?.AssetDocument?.location){
            const location = this.document.AssetDocument.location
            this.uploadObject.name = location.substring(location.lastIndexOf('/')+1)
        }
    }
}
</script>

<style scoped>

</style>