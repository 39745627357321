<template>
    <v-form ref="linkUploadForm" v-model="validForm">
        <v-layout row class="pt-1">
            <v-flex pl-5 py-2 :class="standAlone ? 'xs6 lg5 md5' : 'xs6 lg3 md4'">
                <div class="d-flex flex-row align-center">
                    <div>
                        <v-icon class="ml-1 fas fa-map-pin"></v-icon>
                    </div>
                    <div class="text-no-wrap pl-2">Geo-coordinates Link</div>
                    <div style="width: 100%" class="text-right">
                        <v-icon color="green darken-1">mdi-lock-open-outline</v-icon>
                    </div>
                </div>
            </v-flex>
            <v-flex py-2 :class="standAlone ? 'xs6 lg4 md5' : 'xs6 lg3 md4'">
                <v-text-field
                    :value="Shipment__geo_coords_link"
                    :error-messages="manualErrorState"
                    :rules="[...validationRules.url]"
                    prepend-icon="mdi-paperclip"
                    clearable
                    dense
                    hide-details="auto"
                    solo
                    @change="Shipment__geo_coords_link = $event"
                ></v-text-field>
            </v-flex>
            <div class="d-flex flex-row align-center">
                <v-btn
                    :loading="saving"
                    color="default"
                    small
                    width="70px"
                    @click="saveLink()"
                >{{ $t('message.save') }}</v-btn>
            </div>
            <div class="d-flex flex-row align-center" v-if="Shipment__geo_coords_link != null">
                <v-btn class="ml-3" icon @click="viewLink(Shipment__geo_coords_link)" small>
                    <v-icon>far fa-map</v-icon>
                </v-btn>
            </div>
        </v-layout>
    </v-form>
</template>

<script>
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields"

export default {
    name: "GeoCoordinatesLinkUpload",
    props: ['contractId','updateMode','standAlone'],
    data(){
        return {
            manualErrorState: '',
            saving: false,
            validForm: false
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('shipment',{
            Shipment__geo_coords_link: 'Shipment.geo_coords_link',
            Shipment__id: 'Shipment.id'
        },'statePrefix'),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters([
            'validationRules'
        ])
    },
    methods: {
        ...mapActions('shipment',{
            updateShipment: 'updateShipment'
        }),
        saveLink() {
            if(this.Shipment__geo_coords_link != null) this.manualErrorState == ''
            if(this.$refs.linkUploadForm.validate() && this.manualErrorState == '') {
                this.saving = true;
                this.updateShipment(this.contractId)
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.shipmentUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.saving = false
                        } else {
                            this.$toast.error(this.$t('message.errors.shipmentNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.saving = false
                        }
                    })
                    .catch(() => {
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.linkNotValid'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        viewLink(link) {
            let tab = window.open(link, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    created() {
        this.manualErrorState = ''
    }
}
</script>

<style scoped>

</style>